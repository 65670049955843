import { ClipLoader } from 'react-spinners';
import LoanRepayment from './LoanRepayment.js';
import './PopoutPanel.css';
import { useState } from 'react';

function PopoutPanel({ 
  data = {},
  userState = 'N/A', // Passed explicitly from Dashboard
  loading = false, 
  error = null, 
  closePanel 
}) {
  console.log('PopoutPanel Props:', { data, loading, error, userState });

  const [isCalculated, setIsCalculated] = useState(false);

  if (loading) {
    return (
      <div className="popout-panel">
        <button className="close-btn" onClick={closePanel}>X</button>
        <h2>Loading Career Details...</h2>
        <ClipLoader size={35} color="#4A90E2" />
      </div>
    );
  }

  // Handle error state with a more informative message
  if (error) {
    return (
      <div className="popout-panel">
        <button className="close-btn" onClick={closePanel}>X</button>
        <h2>Error Loading Career Details</h2>
        <p style={{ color: 'red' }}>
          {error.message || 'Oops! Something went wrong.'}
        </p>
        <p>
          {error.details || "Unfortunately we couldn't find details for this career. Please try again at a later time to see if we have updated information that has what you're looking for."}
        </p>
      </div>
    );
  }

  // Handle empty data gracefully
  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="popout-panel">
        <button onClick={closePanel}>Close</button>
        <h2>No Career Data Available</h2>
        <p>Sorry, we couldn’t find any data for this career. It may be missing or unavailable at the moment.</p>
      </div>
    );
  }

  // Safely access nested data with fallbacks
  const { 
    jobDescription = null,  // Default to null if not provided
    tasks = null,  // Default to null if not provided
    title = 'Career Details', 
    economicProjections = {}, 
    salaryData = [], 
    schools = [], 
  } = data;

  const tenthPercentileSalary = salaryData?.find(
    (point) => point.percentile === '10th Percentile'
  )?.value || 0;

  const getProgramLength = (degreeType) => {
    if (degreeType?.includes("Associate")) return 2;
    if (degreeType?.includes("Bachelor")) return 4;
    if (degreeType?.includes("Master")) return 6;
    if (degreeType?.includes("Doctoral") || degreeType?.includes("First Professional")) return 8;
    if (degreeType?.includes("Certificate")) return 1;
    return 4; // Default to 4 years if unspecified
  };

  return (
    <div className="popout-panel">
      <button onClick={closePanel}>Close</button>
      <h2>{title}</h2>

      {/* Job Description and Tasks */}
      <div className="job-description">
        <h3>Job Description</h3>
        <p>{jobDescription || 'No description available'}</p>
      </div>

      <div className="job-tasks">
        <h3>Expected Tasks</h3>
        {tasks && tasks.length > 0 ? (
          <ul>
            {tasks.map((task, index) => (
              <li key={index}>{task}</li>
            ))}
          </ul>
        ) : (
          <p>No tasks available for this career path.</p>
        )}
      </div>

       {/* Schools Offering Programs Section */}
       <h3>Schools Offering Programs</h3>
       <div className="schools-offering">
  {Array.isArray(schools) && schools.length > 0 ? (
    schools.map((school, index) => (
      <div key={index} className="school-card">
        <div><strong>{school['INSTNM']}</strong></div>
        <div>Degree Type: {school['CREDDESC'] || 'Degree type information is not available for this program'}</div>
        <div>In-State Tuition: ${school['In_state cost'] || 'Tuition information is not available for this school'}</div>
        <div>Out-of-State Tuition: ${school['Out_state cost'] || 'Tuition information is not available for this school'}</div>
        <div>Distance: {school['distance'] || 'Distance to school not available'}</div>
        <div>
          Website: <a href={school['Website']} target="_blank" rel="noopener noreferrer">{school['Website']}</a>
        </div>
      </div>
    ))
  ) : (
    <p>No schools of higher education are available for this career path.</p>
  )}
</div>

<div className="economic-projections">
  <h3>Economic Projections for {userState}</h3>
  {economicProjections && typeof economicProjections === 'object' ? (
    <ul>
      <li>2022 Employment: {economicProjections['2022 Employment'] || 'N/A'}</li>
      <li>2032 Employment: {economicProjections['2032 Employment'] || 'N/A'}</li>
      <li>Total Change: {economicProjections['Total Change'] || 'N/A'}</li>
    </ul>
  ) : (
    <p>No economic projections available for this career path.</p>
  )}
</div>

      {/* Salary Data Points */}
      <div className="salary-data">
  <h3>Salary Data</h3>
  {salaryData && salaryData.length > 0 ? (
    <table>
      <thead>
        <tr>
          <th>Percentile</th>
          <th>Salary</th>
        </tr>
      </thead>
      <tbody>
        {salaryData.map((point, index) => (
          <tr key={index}>
            <td>{point.percentile}</td>
            <td>{point.value > 0 ? `$${parseInt(point.value, 10).toLocaleString()}` : 'N/A'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>No salary data is available for this career path.</p>
  )}
</div>

       {/* Loan Repayment Analysis */}
       <div className="loan-repayment-analysis">
        <h3>Loan Repayment Analysis</h3>
        <LoanRepayment
          schools={schools.map((school) => {
            console.log(school['CREDDESC']);
            const years = getProgramLength(school['CREDDESC']);
            return {
              schoolName: school['INSTNM'],
              inState: parseFloat(school['In_state cost'] * years) || 0,
              outOfState: parseFloat(school['Out_state cost'] * years) || 0,
              degreeType: school['CREDDESC'],
            };
          })}
          salaryData={
            tenthPercentileSalary > 0
              ? [{ percentile: '10th Percentile', value: tenthPercentileSalary, growthRate: 0.03 }]
              : []
          }
          earningHorizon={10}
        />
        {!tenthPercentileSalary && (
          <p>
            <em>Salary data unavailable. Loan details are based on cost alone.</em>
          </p>
        )}

        {/* Comparison Section */}
{isCalculated && (
  <div className="school-comparison-container">
    {Array.isArray(schools) && schools.length > 0 ? (
      schools.map((school, index) => (
        <div key={index} className="school-comparison">
          <div><strong>{school['INSTNM']}</strong></div>
          <div>Degree Type: {school['CREDDESC'] || 'Degree type information is not available for this program'}</div>
          <div>In-State Tuition: ${school['In_state cost'] || 'Tuition information is not available for this school'}</div>
          <div>Out-of-State Tuition: ${school['Out_state cost'] || 'Tuition information is not available for this school'}</div>
          <div>Distance: {school['distance'] || 'Distance to school not available'}</div>
          <div>
            Website: <a href={school['Website']} target="_blank" rel="noopener noreferrer">{school['Website']}</a>
          </div>
        </div>
      ))
    ) : (
      <p>No schools available after calculation.</p>
    )}
  </div>
)}

       
      </div>
    </div>
  );
}

export default PopoutPanel;