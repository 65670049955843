import React, { useState } from "react";
import axios from "axios";
import "./Chatbot.css";

const Chatbot = ({ context }) => {
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: "Hi! I’m here to help you with career suggestions, ROI analysis, and any questions you have about AptivaAI. How can I assist you today?",
    },
  ]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  const sendMessage = async (content) => {
    const userMessage = { role: "user", content };
    const contextSummary = `
      Career Suggestions: ${context.careerSuggestions.map((c) => c.title).join(", ") || "No suggestions available."}.
      Selected Career: ${context.selectedCareer?.title || "None"}.
      Schools: ${context.schools.map((s) => s['INSTNM']).join(", ") || "No schools available."}.
      Salary Data: Median salary is ${
        context.salaryData.find((s) => s.percentile === "Median")?.value || "unavailable"
      }.
    `;

    const messagesToSend = [
      { role: "system", content: "You are a helpful assistant providing career advice." },
      { role: "assistant", content: contextSummary },
      ...messages,
      userMessage,
    ];

    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: messagesToSend,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const botMessage = response.data.choices[0].message;
      setMessages([...messages, userMessage, botMessage]);
    } catch (error) {
      console.error("Chatbot Error:", error);
      setMessages([
        ...messages,
        userMessage,
        { role: "assistant", content: "Error: Unable to fetch response. Please try again." },
      ]);
    } finally {
      setLoading(false);
      setInput("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      sendMessage(input.trim());
    }
  };

  return (
    <div className="chatbot-container">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            {msg.content}
          </div>
        ))}
        {loading && <div className="message assistant">Typing...</div>}
      </div>
      <form onSubmit={handleSubmit} className="chat-input-form">
        <input
          type="text"
          placeholder="Ask a question..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit" disabled={loading}>
          Send
        </button>
      </form>
    </div>
  );
};

export default Chatbot;
