import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import './InterestInventory.css';

const InterestInventory = () => {
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const questionsPerPage = 6;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const fetchQuestions = async () => {
    setLoading(true); // Start loading
    setError(null); // Reset error state
    
    const url = '/api/onet/questions?start=1&end=60';
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: { Accept: 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch questions: ${response.statusText}`);
      }

      const data = await response.json();

      if (data && Array.isArray(data.questions)) {
        setQuestions(data.questions);
        console.log("Questions fetched:", data.questions);
      } else {
        throw new Error("Invalid question format.");
      }
    } catch (error) {
      console.error("Error fetching questions:", error.message);
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleResponseChange = (questionIndex, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionIndex]: value,
    }));
  };

  const randomizeAnswers = () => {
    const randomizedResponses = {};
    questions.forEach((question) => {
      randomizedResponses[question.index] = Math.floor(Math.random() * 5) + 1;
    });
    setResponses(randomizedResponses);
  };

  const validateCurrentPage = () => {
    const start = (currentPage - 1) * questionsPerPage;
    const end = currentPage * questionsPerPage;
    const currentQuestions = questions.slice(start, end);

    const unanswered = currentQuestions.filter(
      (q) => !responses[q.index] || responses[q.index] === '0'
    );

    if (unanswered.length > 0) {
      alert(`Please answer all questions before proceeding.`);
      return false;
    }
    return true;
  };

  const handleNextPage = () => {
    if (!validateCurrentPage()) return;
    setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleSubmit = async () => {
    if (!validateCurrentPage()) return;
  
    const answers = Array.from({ length: 60 }, (_, i) => responses[i + 1] || '0').join('');
  
    try {
      setIsSubmitting(true);
      setError(null); // Clear previous errors
      const url = `${process.env.REACT_APP_API_URL}/onet/submit_answers`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ answers }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to submit answers: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log("Careers Response:", data);

      const { careers: careerSuggestions, riaSecScores } = data;

      if (Array.isArray(careerSuggestions) && Array.isArray(riaSecScores)) {
        navigate('/dashboard', { state: { careerSuggestions, riaSecScores } });
      } else {
        console.error("Invalid data format:", data);
        alert("Failed to process results. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting answers:", error.message);
      alert("Failed to submit answers. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const start = (currentPage - 1) * questionsPerPage;
  const end = currentPage * questionsPerPage;
  const currentQuestions = questions.slice(start, end);

  return (
    <div className="interest-inventory-container">
      <h2>Interest Inventory</h2>
      {loading && <ClipLoader size={35} color="#4A90E2" />}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {questions.length > 0 ? (
        <div className="questions-container">
          {currentQuestions.map((question) => (
            <div key={question.index} className="question">
              <label>{question.text}</label>
              <select
                onChange={(e) => handleResponseChange(question.index, e.target.value)}
                value={responses[question.index] || '0'}
              >
                <option value="0">Select an option</option>
                <option value="1">Strongly Dislike</option>
                <option value="2">Dislike</option>
                <option value="3">Neutral</option>
                <option value="4">Like</option>
                <option value="5">Strongly Like</option>
              </select>
            </div>
          ))}
        </div>
      ) : (
        <p>Loading questions.</p>
      )}

      <div className="pagination-buttons">
        {currentPage > 1 && (
          <button type="button" onClick={handlePreviousPage}>Previous</button>
        )}
        {currentPage * questionsPerPage < questions.length ? (
          <button type="button" onClick={handleNextPage}>Next</button>
        ) : (
          <button type="button" onClick={handleSubmit} disabled={isSubmitting}>Submit</button>
        )}
        <button type="button" onClick={randomizeAnswers} disabled={isSubmitting}>Randomize Answers</button>
      </div>
    </div>
  );
};

export default InterestInventory;
