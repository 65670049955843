import axios from 'axios';

//fetch areas by state
export const fetchAreasByState = async (state) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/Institution_data.json`);

    if (response.status === 200) {
      return response.data.areas; // Assume the API returns a list of areas
    } else {
      console.error('Failed to fetch areas:', response.status);
      return [];
    }
  } catch (error) {
    console.error('Error fetching areas:', error.message);
    return [];
  }
};


// Fetch schools
export const fetchSchools = async (cipCode, state = '', level = '', type = '') => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const response = await axios.get(`${apiUrl}/schools`, {
      params: {
        cipCode,
        state,
      },
    });

    return response.data; // Return filtered data
  } catch (error) {
    console.error('Error fetching schools:', error);
    return [];
  }
};
