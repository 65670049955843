import React, { useState } from 'react';
import './LoanRepayment.css';


function LoanRepayment({ schools, salaryData, earningHorizon }) {
  const [tuitionType, setTuitionType] = useState('inState'); // 'inState' or 'outOfState'
  const [interestRate, setInterestRate] = useState(5.5); // Default federal loan interest rate
  const [loanTerm, setLoanTerm] = useState(10); // Default loan term (10 years)
  const [extraPayment, setExtraPayment] = useState(0); // Extra monthly payment
  const [currentSalary, setCurrentSalary] = useState(0); // Current salary input
  const [selectedSalary, setSelectedSalary] = useState('A_PCT10');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Validation function
  const validateInputs = () => {
    if (!schools || schools.length === 0) {
      setError('School data is missing. Loan calculations cannot proceed.');
      return false;
    }

    if (interestRate <= 0) {
      setError('Interest rate must be greater than 0.');
      return false;
    }

    if (loanTerm <= 0) {
      setError('Loan term must be greater than 0.');
      return false;
    }

    if (extraPayment < 0) {
      setError('Extra monthly payment cannot be negative.');
      return false;
    }

    if (currentSalary < 0) {
      setError('Current salary cannot be negative.');
      return false;
    }

    setError(null); // Clear errors if valid
    return true;
  };

  // Loan calculation function for all schools
  const calculateLoanDetails = () => {
    if (!validateInputs()) return; // Validate inputs before calculation

    setLoading(true); // Set loading state to true while calculation is ongoing

    const schoolResults = schools.map((school) => {
      const tuition = tuitionType === 'inState' ? school.inState : school.outOfState;
      const monthlyRate = interestRate / 12 / 100;
      const loanTermMonths = loanTerm * 12;
      const degreeType = school.degreeType;

      console.log(school.degreeType);
      console.log(schools); // Log to see if degreeType is correctly passed

      // Calculate minimum monthly payment
      const minimumMonthlyPayment = tuition * (monthlyRate * Math.pow(1 + monthlyRate, loanTermMonths)) /
        (Math.pow(1 + monthlyRate, loanTermMonths) - 1);

      // Total loan cost with extra payments
      const extraMonthlyPayment = minimumMonthlyPayment + extraPayment;
      let remainingBalance = tuition;
      let monthsWithExtra = 0;

      while (remainingBalance > 0) {
        monthsWithExtra++;
        const interest = remainingBalance * monthlyRate;
        const principal = extraMonthlyPayment - interest;
        remainingBalance -= principal;
      }

      const totalLoanCost = extraMonthlyPayment * monthsWithExtra;

      // Debug: log salaryData and selectedSalary
      console.log('Salary Data:', salaryData);
      console.log('Selected Salary:', selectedSalary);

      let salary = null;
      
      if (salaryData && salaryData[0]) {
        // Access the salary value from salaryData using the selectedSalary as the key
        salary = salaryData[0].value;  // Directly get the 'value' field
      }
      // Debug: log salary after extraction
      console.log('Extracted Salary:', salary);
  
      let netGain = 'N/A';
      let monthlySalary = 'N/A';
      if (salary !== null && salary !== undefined && !isNaN(salary)) {
        // Calculate net gain
        const totalSalary = salary * earningHorizon;
        const currentSalaryEarnings = currentSalary * earningHorizon * Math.pow(1.03, earningHorizon); // 3% growth
        netGain = (totalSalary - totalLoanCost - currentSalaryEarnings).toFixed(2);

        // Monthly salary calculation (divide salary by 12)
        monthlySalary = (salary / 12).toFixed(2);
      }

      return {
        ...school,
        tuition,
        degreeType,
        monthlyPayment: minimumMonthlyPayment.toFixed(2),
        totalMonthlyPayment: extraMonthlyPayment.toFixed(2), // Add total payment including extra
        totalLoanCost: totalLoanCost.toFixed(2),
        netGain,
        monthlySalary,
      };
    });

    setResults(schoolResults);
    setLoading(false); // Reset loading state once the calculation is complete
  };


  return (
    <div className="loan-repayment-container">
      <div className="loan-repayment-fields">
        <label>
          Tuition Type:
          <select value={tuitionType} onChange={(e) => setTuitionType(e.target.value)}>
            <option value="inState">In-State</option>
            <option value="outOfState">Out-of-State</option>
          </select>
        </label>

        <label>
          Interest Rate (%):
          <input 
            type="number" 
            value={interestRate} 
            onChange={(e) => setInterestRate(Number(e.target.value))} 
            onFocus={(e) => e.target.select()} 
            min="0" 
          />
        </label>

        <label>
          Loan Term (Years):
          <input 
            type="number" 
            value={loanTerm} 
            onChange={(e) => setLoanTerm(Number(e.target.value))} 
            onFocus={(e) => e.target.select()} 
            min="0" 
          />
        </label>

        <label>
          Extra Monthly Payment ($):
          <input 
            type="number" 
            value={extraPayment} 
            onChange={(e) => setExtraPayment(Number(e.target.value))} 
            onFocus={(e) => e.target.select()} 
            min="0" 
          />
        </label>

        <label>
          Current Salary (Gross Annual $):
          <input
            type="number"
            value={currentSalary}
            onChange={(e) => setCurrentSalary(e.target.value)} 
            onFocus={(e) => e.target.select()} 
            min="0" 
          />
        </label>

        <label>
          Expected Salary:
          <select value={selectedSalary} onChange={(e) => setSelectedSalary(e.target.value)}>
            <option value="A_PCT10">10th Percentile</option>
            <option value="A_PCT25">25th Percentile</option>
            <option value="A_PCT50">50th Percentile</option>
            <option value="A_PCT75">75th Percentile</option>
            <option value="A_PCT90">90th Percentile</option>
          </select>
        </label>

        <button onClick={calculateLoanDetails} disabled={loading}>
          {loading ? 'Calculating...' : 'Calculate'}
        </button>
      </div>

      {/* Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Results Display */}
      {results.length > 0 && (
        <div className="results-container">
          <h3>Comparisons by School - assumes a starting salary in the lowest 10%</h3>
          {results.map((result, index) => (
            <div className="school-result-card" key={index}>
             <h4>{result.schoolName} - {result.degreeType || 'Degree type not available'}</h4>
            <p>Total Tuition: ${result.tuition}</p>
            <p>Monthly Payment: ${result.monthlyPayment}</p>
            <p>Total Monthly Payment (with extra): ${result.totalMonthlyPayment}</p>
            <p>Total Loan Cost: ${result.totalLoanCost}</p>
            <p className={`net-gain ${parseFloat(result.netGain) < 0 ? 'negative' : 'positive'}`}>
              Net Gain: {result.netGain}
            </p>
            <p>Monthly Salary (Gross): {result.monthlySalary}</p>
          </div>
          ))}
        </div>
      )}

      {/* Salary Warning */}
      {!salaryData || salaryData.length === 0 ? (
        <p style={{ color: 'red' }}>Salary data is not available for this profession. Loan calculations are limited.</p>
      ) : null}
    </div>
  );
}

export default LoanRepayment;
