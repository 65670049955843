import React from 'react';
import './CareerSuggestions.css';

export function CareerSuggestions({ careerSuggestions = [], onCareerClick }) {
  if (!Array.isArray(careerSuggestions) || careerSuggestions.length === 0) {
    return <p>No career suggestions available. Please check back later.</p>;
  }

  return (
    <div>
      <h2>Career Suggestions</h2>
      <div className="career-suggestions-grid">
        {careerSuggestions.map((career) => (
          <button 
            key={career.code} 
            className="career-button"
            onClick={() => onCareerClick(career)}
          >
            {career.title}
          </button>
        ))}
      </div>
    </div>
  );  
  };
  
  export default CareerSuggestions;