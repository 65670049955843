import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignIn.css';

function SignIn({ setIsAuthenticated }) {
  console.log('SignIn component loaded');

  const navigate = useNavigate();
  const usernameRef = useRef('');
  const passwordRef = useRef('');
  const [error, setError] = useState(''); // Still needs state for UI updates

  const handleSignIn = async (event) => {
    event.preventDefault();
    
    setError('');

    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    if (!username || !password) {
      setError('Please enter both username and password');
      return;
    }
    try {
      // Make a POST request to the backend for authentication
      const response = await fetch('https://dev.aptivaai.com/api/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to sign in');
      }
  
      const data = await response.json();
      console.log('SignIn response data:', data);

      const { token, userId } = data;
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);

      console.log('Token and userId saved in localStorage');
      console.log('SignIn response data:', data);
      // Call setIsAuthenticated(true) to update the state
      setIsAuthenticated(true);
      navigate('/getting-started'); // Redirect to GettingStarted after SignIn
    } catch (error) {
      console.error('Sign-In Error:', error.message);
      setError(error.message); // Display error message to the user
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-form">
        <h1>Sign In</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSignIn}>
          <input
            type="text"
            placeholder="Username"
            ref={usernameRef} // Use ref instead of state
            className="signin-input"
          />
          <input
            type="password"
            placeholder="Password"
            ref={passwordRef} // Use ref instead of state
            className="signin-input"
          />
          <button type="submit" className="signin-button">
            Sign In
          </button>
        </form>
        <p>Don’t have an account? <Link to="/signup">Sign Up</Link></p>
      </div>
    </div>
  );
}

export default SignIn;
